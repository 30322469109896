import { postRequest, deleteRequest, getRequest } from './index'
import { SRCURL } from '@/config'

// 购物车-加入购物车
export const tradeShoppingCartAdd = (data, successCallback, failureCallback) => {
  postRequest(`${SRCURL}/trade/shopping/cart/add`, data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 购物车-查询购物车列表
export const tradeShoppingCartList = (successCallback, failureCallback) => {
  getRequest(`${SRCURL}/trade/shopping/cart/list`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 购物车-删除购物车商品
export const goodsHallDetailDetailSpotHallGoods = (data, successCallback, failureCallback) => {
  deleteRequest(`${SRCURL}/trade/shopping/cart/remove/${data}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 购物车-修改购物车商品重量
export const tradeShoppingCartUpdate = (data, successCallback, failureCallback) => {
  postRequest(`${SRCURL}/trade/shopping/cart/update/${data.id}?purchaseWeight=${data.num}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
