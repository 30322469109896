import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import App from './App.vue'
import router from './router'
import store from './store'
import BaiduMap from 'vue-baidu-map'
import '@/assets/css/normalize.scss'
import { getDictNameByData } from '@/unit/valueCode'
import { BSERURL, projectUrl, scfPlatformUrl, agentUrl, IMGPATH } from './config/index'
Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(BaiduMap, {
  ak: 'iie25a9U2i5FS3nlfS7skclLigGcSMd2' // ak 是在百度地图开发者平台申请的** 详见 http://lbsyun.baidu.com/apiconsole/key */
})
try {
  Vue.prototype.$fileUrl = JSON.parse(localStorage.getItem('dictionaryEntry'))['documentLeadingEdge'][0].dictName
} catch (err) {
  console.log('HT369-V1.0-202212221' + err)
}
Vue.prototype.$localPicture = IMGPATH // 本地写代码图片地址
Vue.prototype.$baseUpload = `${BSERURL}/file/uploadfile`
Vue.prototype.$projectUrl = projectUrl
Vue.prototype.$scfPlatformUrl = scfPlatformUrl
Vue.prototype.$agentUrl = agentUrl
Vue.prototype.$getDictNameByData = getDictNameByData
new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
