import { getRequest, postRequest } from './index'
import { BSERURL, CMSSRCUL, APISRCUL } from '@/config'
// 获取省市区
export const getSelectAreaTree = (areaType, successCallback, failureCallback) => {
  getRequest(`${BSERURL}/dataDict/selectAreaTree?areaType=` + areaType, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 获取字典数据
export const getSelectAllDict = new Promise((resolve, reject) => {
  getRequest(`${BSERURL}/dataDict/selectAllDict`, {}, res => (
    resolve(res)
  ), error => {
    reject(error)
  })
})
// 获取新闻列表
export const getNewsListByPage = (data, successCallback, failureCallback) => {
  postRequest(`${CMSSRCUL}/news/newsByPage`, data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 获取新闻详情
export const getNewsByDetail = (id, successCallback, failureCallback) => {
  getRequest(`${CMSSRCUL}/news/newsByDetail?id=${id}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 商城首页-平台交易指数-列表
export const getPlatTradeIndexList = (data, successCallback, failureCallback) => {
  postRequest(`${APISRCUL}/goods/hall/getPlatTradeIndexList`, data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 商城首页-平台交易指数-图表
export const getPlatTradeIndexChart = (data, successCallback, failureCallback) => {
  postRequest(`${APISRCUL}/goods/hall/getPlatTradeIndexChart`, data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 商城-查询交易时间规则
export const tradeTimeQueryTradeTimeRule = (successCallback, failureCallback) => {
  getRequest(`${BSERURL}/trade_time/queryTradeTimeRule`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 商城-查询交易时间是否在营业时间范围内
export const tradeTimeQueryTradeTimeState = (successCallback, failureCallback) => {
  getRequest(`${BSERURL}/trade_time/queryTradeTimeState`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
