import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'portalWebsite',
    component: () => import('@/views/index.vue'),
    meta: {
      title: '门户网站'
    },
    redirect: '/homePage/index',
    children: [
      {
        path: '/homePage/index',
        name: 'portalWebsiteIndex',
        component: () => import('@/views/homePage/index.vue'),
        meta: {
          title: '首页'
        }
      },
      {
        path: '/spotHall/index',
        name: 'spotHall',
        component: () => import('@/views/spotHall/index.vue'),
        meta: {
          title: '现货大厅'
        }
      },
      {
        path: '/spotHall/spotHallDetail',
        name: 'spotHallDetail',
        component: () => import('@/views/spotHall/spotHallDetail.vue'),
        meta: {
          title: '现货大厅'
        }
      },
      {
        path: '/collection/index',
        name: 'collectionIndex',
        component: () => import('@/views/collection/index.vue'),
        meta: {
          title: '集采公告'
        }
      },
      {
        path: '/collection/notice',
        name: 'collectionNotice',
        component: () => import('@/views/collection/notice.vue'),
        meta: {
          title: '集采公告'
        }
      },
      {
        path: '/shoppingCart/index',
        name: 'shoppingCart',
        component: () => import('@/views/shoppingCart/index.vue'),
        meta: {
          title: '购物车'
        }
      },
      {
        path: '/buyHall/index',
        name: 'buyHall',
        component: () => import('@/views/buyHall/index.vue'),
        meta: {
          title: '求购大厅'
        }
      },
      {
        path: '/orderManagement/index',
        name: 'orderManagement',
        component: () => import('@/views/orderManagement/index.vue'),
        meta: {
          title: '确认订单'
        }
      },
      {
        path: '/orderManagement/orderPayment',
        name: 'orderPayment',
        component: () => import('@/views/orderManagement/orderPayment.vue'),
        meta: {
          title: '订单支付'
        }
      },
      {
        path: '/buyHall/wantBuyAdd',
        name: 'wantBuyAdd',
        component: () => import('@/views/buyHall/wantBuyAdd.vue'),
        meta: {
          title: '发布求购'
        }
      },
      {
        path: '/buyHall/wantBuyDetail',
        name: 'wantBuyDetail',
        component: () => import('@/views/buyHall/wantBuyDetail.vue'),
        meta: {
          title: '求购详情'
        }
      },
      {
        path: '/news/index',
        name: 'news',
        component: () => import('@/views/news/index.vue'),
        meta: {
          title: '新闻列表'
        }
      },
      {
        path: '/news/newsDetail',
        name: 'newsDetail',
        component: () => import('@/views/news/newsDetail.vue'),
        meta: {
          title: '新闻详情'
        }
      },
      {
        path: '/industryFinance/index',
        name: 'industryFinance',
        component: () => import('@/views/industryFinance/index.vue'),
        meta: {
          title: '产融超市',
          keepAlive: true
        }
      },
      {
        path: '/industryFinance/industryFinanceDetails',
        name: 'industryFinanceDetails',
        component: () => import('@/views/industryFinance/pages/industryFinanceDetails.vue'),
        meta: {
          title: '产融超市详情'
        }
      },
      {
        path: '/storeManagement/index',
        name: 'storeManagementIndex',
        component: () => import('@/views/storeManagement/index.vue'),
        meta: {
          title: '优选店铺'
        }
      },
      {
        path: '/storeManagement/storeHomepage',
        name: 'storeHomepage',
        component: () => import('@/views/storeManagement/pages/storeHomepage.vue'),
        meta: {
          title: '店铺主页'
        }
      },
      {
        path: '/storeManagement/ourStoreGoods',
        name: 'ourStoreGoods',
        component: () => import('@/views/storeManagement/pages/ourStoreGoods.vue'),
        meta: {
          title: '本店产品'
        }
      },
      {
        path: '/processingServices/index',
        name: 'processingServicesIndex',
        component: () => import('@/views/processingServices/index.vue'),
        meta: {
          title: '加工服务'
        }
      },
      {
        path: '/processingServices/processServicesHome',
        name: 'processServicesHome',
        component: () => import('@/views/processingServices/processServicesHome.vue'),
        meta: {
          title: '加工主页'
        }
      },
      {
        path: '/processingServices/inquiry',
        name: 'inquiry',
        component: () => import('@/views/processingServices/inquiry.vue'),
        meta: {
          title: '加工询价'
        }
      }
    ]
  },
  {
    path: '/fourGoldenTreasures/index',
    name: 'fourGoldenTreasures',
    component: () => import('@/views/fourGoldenTreasures/index.vue'),
    meta: {
      title: '四大金链'
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/login.vue'),
    meta: {
      title: '登录'
    }
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/login/register.vue'),
    meta: {
      title: '注册'
    }
  },
  {
    path: '/agreement',
    name: 'agreement',
    meta: {
      title: '平台协议'
    },
    component: () => import('@/views/login/agreement.vue')
  },
  {
    path: '*',
    name: '404',
    meta: {
      title: '404'
    },
    component: () => import('@/views/wrongIndication/404.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // 兼容chrome
  document.body.scrollTop = 0
  // 兼容firefox
  document.documentElement.scrollTop = 0
  // 兼容safari
  window.pageYOffset = 0
  next()
})

// 获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
// 修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err)
}

export default router
