
let SRCURL = ''
let BSERURL = ''
let SCFURL = ''
let CMSSRCUL = ''
let APISRCUL = ''
let projectUrl = '' // 商城地址
let scfPlatformUrl = '' // 供应链地址
let agentUrl = ''
let IMGPATH = 'http://120.77.45.206/images/'
let SCMAPI = ''// 集采地址
let ICFSAPI = ''// 产融超市地址
switch (true) {
  case location.href.indexOf('47.104.208.84') >= 0: // 生产环境
    SRCURL = `https://api.369tecgroup.com/scm/v1`
    BSERURL = `https://api.369tecgroup.com/plat/approveabc/`
    SCFURL = `https://api.369tecgroup.com/scf/approveabc/`
    CMSSRCUL = `https://api.369tecgroup.com/cms/approveabc`
    APISRCUL = `https://api.369tecgroup.com/scm/api/v1`
    projectUrl = `https://b2b.369tecgroup.com/scm/#/`
    scfPlatformUrl = `https://api.369tecgroup.com/scf/#/`
    agentUrl = `https://agent.369tecgroup.com/#/login`
    IMGPATH = `https://image.369tecgroup.com/images/`
    SCMAPI = `https://api.369tecgroup.com/scm/api/`
    ICFSAPI = `https://icf.369tecgroup.com/supermarket/v1/`
    break
  case location.href.indexOf('369tecgroup') >= 0: // 生产环境
    SRCURL = `https://api.369tecgroup.com/scm/v1`
    BSERURL = `https://api.369tecgroup.com/plat/approveabc/`
    SCFURL = `https://api.369tecgroup.com/scf/approveabc/`
    CMSSRCUL = `https://api.369tecgroup.com/cms/approveabc`
    APISRCUL = `https://api.369tecgroup.com/scm/api/v1`
    projectUrl = `https://b2b.369tecgroup.com/scm/#/`
    scfPlatformUrl = `https://api.369tecgroup.com/scf/#/`
    agentUrl = `https://agent.369tecgroup.com/#/login`
    IMGPATH = `https://image.369tecgroup.com/images/`
    SCMAPI = `https://api.369tecgroup.com/scm/api/`
    ICFSAPI = `https://icf.369tecgroup.com/supermarket/v1/`
    break
  case location.href.indexOf('120.77.45.206') >= 0: // 测试环境
    SRCURL = `http://120.77.45.206:8086/scm/v1`
    BSERURL = `http://120.77.45.206:8086/plat/approveabc/`
    SCFURL = `http://120.77.45.206:8086/scf/approveabc/`
    CMSSRCUL = `http://120.77.45.206:8086/cms/approveabc`
    APISRCUL = `http://120.77.45.206:8086/scm/api/v1`
    projectUrl = `http://120.77.45.206/scm-test/#/`
    scfPlatformUrl = `http://120.77.45.206/scf-test/#/`
    agentUrl = `http://120.77.45.206/agent-test/#/login`
    SCMAPI = `http://120.77.45.206:8086/scm/api/`
    ICFSAPI = `http://120.77.45.206:1000/supermarket/v1/`
    break
  case location.href.indexOf('120.25.107.214') >= 0: // 之前的测试环境现在改为开发环境了
    SRCURL = `http://120.25.107.214:8086/scm/v1`
    BSERURL = `http://120.25.107.214:8086/plat/approveabc/`
    SCFURL = `http://120.25.107.214:8086/scf/approveabc/`
    CMSSRCUL = `http://120.25.107.214:8086/cms/approveabc`
    APISRCUL = `http://120.25.107.214:8086/scm/api/v1`
    projectUrl = `http://120.25.107.214/scm-test/#/`
    scfPlatformUrl = `http://120.25.107.214/scf-test/#/`
    agentUrl = `http://120.25.107.214/agent-test/#/login`
    SCMAPI = `http://120.25.107.214:8086/scm/api/`
    ICFSAPI = `http://120.25.107.214:1000/supermarket/v1/`
    break
  case location.pathname.indexOf('-pre') >= 0: // pre测试环境
    SRCURL = `http://112.74.164.238:8086/scm/v1`
    BSERURL = `http://112.74.164.238:8086/plat/approveabc/`
    SCFURL = `http://112.74.164.238:8086/scf/approveabc/`
    CMSSRCUL = `http://112.74.164.238:8086/cms/approveabc`
    APISRCUL = `http://112.74.164.238:8086/scm/api/v1`
    projectUrl = `http://112.74.164.238/scm-pre/#/`
    scfPlatformUrl = `http://112.74.164.238/scf/#/`
    agentUrl = `http://112.74.164.238/agent-pre/#/login`
    SCMAPI = `http://112.74.164.238:8086/scm/api/`
    ICFSAPI = `http://112.74.164.238:1000/supermarket/v1/`
    break
  default:
    SRCURL = `http://120.25.107.214:8086/scm/v1`
    BSERURL = `http://120.25.107.214:8086/plat/approveabc/`
    SCFURL = `http://120.25.107.214:8086/scf/approveabc/`
    CMSSRCUL = `http://120.25.107.214:8086/cms/approveabc`
    APISRCUL = `http://120.25.107.214:8086/scm/api/v1`
    projectUrl = `http://120.25.107.214:8086/scm-test/#/`
    scfPlatformUrl = `http://120.25.107.214:8086/scf-test/#/`
    agentUrl = `http://120.25.107.214:8086/agent-dev/#/login`
    SCMAPI = `http://120.25.107.214:8086/scm/api/`
    ICFSAPI = `http://120.25.107.214:1000/supermarket/v1/`
    break
}
export {
  BSERURL,
  IMGPATH,
  SRCURL,
  APISRCUL,
  CMSSRCUL,
  SCFURL,
  projectUrl,
  scfPlatformUrl,
  agentUrl,
  ICFSAPI,
  SCMAPI
}
